import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';


import 'swiper/css';

export default class MeetTheTeam {
    constructor() {
        const wrapper = document.querySelectorAll('.team-swiper-wrapper');

        if(!wrapper.length) {
            return;
        }

        wrapper.forEach((wrap) => {
            const TeamSwiper = new Swiper('.team-swiper', {

                // autoplay: {
                //     delay: 4000,
                //     pauseOnMouseEnter: true,
                // },

                slidesPerView: 1,
                spaceBetween: 75,
                loop: true,
                autoHeight: true,

                breakpoints: {
                    1025: {
                        slidesPerView: 4, 
                        // autoplay: {
                        //     delay: 4000,
                        // }
                        
                    }
                },

                pagination: {
                    el: '.swiper-pagination',
                },

                modules: [ Pagination, Autoplay ],

            })
        });
    }
}